import httpClient from "@/services/http.js"

const baseURL = process.env.VUE_APP_AUTH_SERVICE_URL

class AuthService {

  async login(username, password) {
    return await httpClient
      .post(baseURL + "/login", {
        uid: username.toLowerCase(),
        password: password
      })
      .then(res => res.data)
  }

  requestPasswordReset(email) {
    return httpClient
      .post(baseURL + "/request-password-reset", { uid: email.toLowerCase() })
      .then(r => r.data)
  }

  saveNewPassword(email, password, token) {
    return httpClient
      .post(baseURL + "/password-reset", {
        uid: email,
        password: password,
        token: token
      })
      .then(r => r.data)
  }

  // registerUser(email, password) {
  //   return httpClient
  //     .post(baseURL + "/register", { uid: email.toLowerCase(), password: password })
  //     .then(r => r.data)
  // }
}

export default new AuthService()
