<template>
  <div class="styledinput-container">
    <input 
      :type="type" 
      v-model="localvalue" 
      @input="update()" 
      @blur="edited = true"
      @keydown.enter="$emit('complete')"
      @keydonw.esc="$emit('abort')"
      :placeholder="placeholder" 
      :class="{ valid: inputValid, invalid: inputInvalid }"/>
    <div v-if="inputValid" class="icon checkmark">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm4.563 5.353c-.251-.228-.623-.248-.887-.057l-.084.072-4.931 4.931-2.289-2.287c-.279-.253-.706-.25-.97.015-.245.245-.266.63-.065.908l.075.088 2.79 2.79c.252.228.623.247.887.056l.084-.071 5.449-5.45c.223-.247.235-.618.04-.886l-.074-.085-.025-.024z"/>
      </svg>
    </div>
    <div v-if="inputInvalid" class="icon cross">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StyledInput',
  props: {
    value: {
      type: String,
      required: true
    },
    validFn: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    }
  },
  data() {
    return {
      localvalue: '',
      edited: false,
    }
  },
  methods: {
    update() {
      this.edited = true
      this.$emit('input', this.localvalue)
    }
  },
  computed: {
    inputValid() {
      return this.edited && this.validFn(this.value)
    },
    inputInvalid() {
      return this.edited && !this.validFn(this.value)
    }
  },
  watch: {
    value() {
      this.localvalue = this.value
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.styledinput-container {
  position: relative;
}

input {
  width: 100%;
  box-sizing: border-box;
  background-color: $weird-grey;
  border-radius: 6px;
  color: $dark;
  outline: none;
  border: none;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  
  &:focus {
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.5);
  }

  &::placeholder {
    color: rgba($primary, 0.2);
  }
}

.valid {
  border: 1px solid $green !important;
  border-radius: 6px;
}

.invalid {
  border: 1px solid $red !important;
  border-radius: 6px;
}

.checkmark {
  fill: $green;
}

.icon {
  position: absolute;
  top: 4px;
  right: 4px;
}

.cross {
  color: white;
  background-color: $red;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
</style>
