import axios from 'axios'

import ErrorHandler from '@/components/utility/ErrorHandler.vue'

const isHandlerEnabled = (config={}) => {
  // FIXME: axios 0.19.0 does not allow for custom config fields.
  // a new release should allow custom or meta fields to be added
  // eslint-disable-next-line no-prototype-builtins
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ? 
    false : true
}

const errorHandler = (err) => {
  if (isHandlerEnabled(err.config)) {
    ErrorHandler.methods.error(err)
  }
  return Promise.reject({ ...err })
}

axios.interceptors.response.use(
  response =>  response,
  error => errorHandler(error)
)

export default axios